import React from "react";
import kebabCase from "lodash/kebabCase";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout pageTitle="Tags">
    <h1 className="page-header">Tags</h1>
    <ul>
      {group.map((tag) => (
        <li key={tag.tag}>
          <Link to={`/tags/${kebabCase(tag.tag)}/`}>
            {tag.tag} ({tag.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMdx(limit: 1000) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`;

export default TagsPage;
